export const PlanStatusEnum = {
  1: "未开始",
  2: "结束",
  3: "取消",
};

export const RecordStatusEnumForTeacher = {
  1: "待确认",
  2: "已上课",
  3: "未上课",
  4: "已取消",
  5: "异常",
  6: "班主任确认中",
};

export const RecordStatusEnumForLearner = {
  1: "",
  2: "导师已上课",
  3: "导师未上课",
  4: "已取消上课",
  5: "异常",
  6: "班主任确认中",
};

export const DayWeek = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];

export function Hours() {
  let hours = [];
  for (let h = 0; h < 24; h++) {
    const val = h < 10 ? `0${h}` : `${h}`;
    hours.push(val);
  }
  return hours;
}

export function Minutes() {
  let minutes = ["00", "15", "30", "45"];
  // for (let m = 0; m < 6; m++) {

  //   minutes.push(m + "0");
  //   minutes.push(m + "5");

  // }
  return minutes;
}

export function Duration() {
  let duration = [];
  for (let i = 60; i <= 12 * 60; ) {
    duration.push({
      value: i,
      label: "",
    });
    i = i + 30;
  }
  duration = duration.map((v) => ({
    ...v,
    label:
      v.value % 60 === 0
        ? `${v.value / 60}小时`
        : `${Math.floor(v.value / 60)}小时30分钟`,
    hour: Math.floor(v.value / 60),
    min: v.value % 60 === 0 ? 0 : 30,
  }));
  return duration;
}
