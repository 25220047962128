<template>
  <button 
    @click="$emit('click')"
    :class="[size || 'middle', {'disabled': disabled || loading}, {'theme': type === 'theme'}, {'plain': type === 'plain'}]" 
    :disabled="disabled || loading">
    <slot />
  </button>
</template>
<script>
/* 参数说明
  size 
    type: String
    options: middle | large | flex 
    default: middle

    - flex布局： 父盒子必须为display:flex; 按钮大小占 flex 1， font-size 继承父盒子

  disabled
    type: String
    options: '' | disabled
    default: ''

  loading
    type: Boolean
    options: false | true
    default: false
*/
export default {
  props: ['size', 'type', 'disabled', 'loading']
}
</script>

<style lang="stylus" scoped>
@import "../../theme.styl"
  button
    background red
    border-radius 4px
    color #fff
    outline 0 none
    border 0 none
    padding 0 
    display flex
    align-items center
    justify-content center
    &+button
      margin-left 12px
    &.middle,&.flex
      background #F3F4F6
      border-radius 21px
      font-size fz16px
      color #10213F
      line-height 1
      width 110px
      height 42px
    &.flex
      flex 1
      height 100%
      font-size inherit
    &.large
      width 355px
      height 50px
      display block
      margin 0 auto
      font-size fz18px
    
    &.theme
      background theme !important
      color #fff !important
    &.plain
      width: 66px;
      height: 28px;
      border-radius: 4px;
      border: 1px solid theme
      background transparent
      color: theme;
      font-size fz12px
</style>
